<template>
    <div class="section-2">
        <div class="container">
            <div class="row p-0 m-0">
                <div class="col-12 col-lg-12">
                    <div class="">
                        <router-link to="/" class="fl-x-cl mt-4">
                            <img class="logo" src="../assets/web/login/logoo.png">
                        </router-link>
                        <div class="fl-x-cr i-icon">
                            <p class="mb-1 mr-3 fs--1 font-lato-light">sign up with</p>

                            <img class="fb flj-c mr-2" src="../assets/web/login/fb.png">

                            <img class="gugl fl-j-c" src="../assets/web/login/g.png">
                        </div>
                    </div>
                    <div class="dg-sm-mt" align="center">
                        <p class="font-jost-book  fs-4 mb-2 mg margin-1">Welcome to KLDB Training Programs</p>
                        <p class="font-jost-semi-bold fs-lg-9 mb-0">Registration Portal</p>
                        <p @click="$router.push('/login/')" class="font-inter-regular text-white-2 fs--1 pb-4">Already have an Account ?
                            <a href="#" class="line-bottom text-decoration-none"> Sign in</a></p>

                        <div class="px-lg-9">
                            <p @click="$router.push('/register/')" class="font-lato-semi-bold ml-4 user-reg-in"
                               align="left">Personal Details</p>
                            <b-form :save-url="registerUrl" @success="formSuccess" v-slot="{model, loading}"
                                    :save-params="{user_type:'Individual'}">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Name*" border-radius="0" name="Name"
                                                         id="name" v-model="model.name" :disabled="loading"
                                                         class=" place-holder-white c-input bdr-radius">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Create A Password*" border-radius="0"
                                                         name="Password" type="password" id="Password"
                                                         v-model="model.password" :disabled="loading"
                                                         class=" place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-vue-select border-radius="0" size="sm" name="Gender"
                                                              class="mb-3 c-input-select-b-white-1 place-holder-white c-input "
                                                              aria-placeholder="Select Gender" :disabled="loading"
                                                              :options="genderOptions" v-model="model.gender"
                                                              placeholder="Gender"></validated-vue-select>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Confirm New Password*" border-radius="0"
                                                         name="Confirm Password" type="password" id="Confirm Password"
                                                         v-model="model.confirm_password" :disabled="loading"
                                                         class=" place-holder-white c-input">
                                        </validated-input>
                                    </div>

                                    <div class="col-lg-6">
                                        <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" size="sm" placeholder="Date Of Birth"
                                                               border-radius="0" v-model="model.dob"
                                                               :disabled="loading" name="Dob"
                                                               style="color: white !important;" :disabled-date="disabledDateAfterToday"
                                                               class=" place-holder-white c-input icon-white input-white">
                                        </validated-date-picker>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="House/ Flat No./ Building/Apartment"
                                                         border-radius="0" name="Address" id="Address"
                                                         class=" place-holder-white c-input"
                                                         v-model="model.address" :disabled="loading">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Phone*" border-radius="0"
                                                         name="Phone Number" type="number" id="Phone"
                                                         v-model="model.phone_number" :rules="{max: 10, min: 10}"
                                                         :disabled="loading" class="num-hide place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Area/Street/Village" border-radius="0"
                                                         name="Area" id="Area" v-model="model.area"
                                                         :disabled="loading" class="place-holder-white c-input">
                                        </validated-input>
                                    </div>

                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Email Id*" border-radius="0"
                                                         name="Email" type="email" v-model="model.email"
                                                         :disabled="loading"
                                                         class=" place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Town/City" border-radius="0"
                                                         name="City" id="City" v-model="model.city"
                                                         :disabled="loading" class="py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Aadhar Number" border-radius="0"
                                                         name="Adhar number" type="number" id="Aadhar Number"
                                                         v-model="model.adhar_number" :rules="{max: 12, min: 12}"
                                                         :disabled="loading" class="num-hide place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-ajax-vue-select border-radius="0" size="sm" name="State"
                                                                   class="mb-3 c-input-select-b-white-1 place-holder-white c-input "
                                                                   aria-placeholder="Select State"
                                                                   :disabled="loading" :url="stateOptions"
                                                                   v-model="model.state" placeholder="State"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-vue-select border-radius="0" size="sm" name="Marital Status"
                                                              class="mb-3 c-input-select-b-white-1 place-holder-white c-input "
                                                              aria-placeholder="Select Marital Status"
                                                              :disabled="loading" :options="maritalStatusOptions"
                                                              v-model="model.marital_status"
                                                              placeholder="Marital Status"></validated-vue-select>
                                    </div>

                                    <div class="col-lg-6">
                                        <validated-input size="sm" name="Pincode" placeholder="Pincode"
                                                         border-radius="0" v-model="model.pincode" :disabled="loading"
                                                         type="number" class="num-hide place-holder-white c-input">
                                        </validated-input>
                                    </div>
<!--                                    <div class="col-12">-->
<!--                                        <p class="text-white text-left text-danger">Fields marked with an asterisk (*) are required.</p>-->
<!--                                    </div>-->

                                </div>
                                <btn border-radius="0" class="mt-43 fs--1 px-7 py-21 mb-4 bdr-radius" text="Register"></btn>
                            </b-form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '../data/urls';

export default {
    name: 'RegisterIndividual',
    data () {
        return {
            stateOptions: urls.masterData.State.stateOptions,
            registerUrl: urls.auth.register,
            genderOptions: [
                { value: 'M', label: 'Male' },
                { value: 'F', label: 'Female' },
                { value: 'O', label: 'Others' }
            ],
            maritalStatusOptions: [
                { label: 'Married', value: 'Married' },
                { label: 'Unmarried', value: 'Unmarried' },
                { label: 'Divorced', value: 'Divorced' }
            ]
        };
    },
    methods: {
        disabledDateAfterToday (date) {
            const today = new Date();
            return date > today;
        },
        formSuccess () {
            this.$notify('Registered Successfully', 'Success', {
                type: 'success'
            });
            this.$router.push('/login/');
        }
    }
};
</script>

<style scoped lang="scss">
.section-2 {
    background-image: url("../assets/web/register/bgi.png");
    background-position: center 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}
.num-hide{
    -webkit-appearance: none;
}
.container {
    color: white;
}

.i-icon {
    position: absolute;
    top: 56px;
    right: -8px;
}

.logo {
    height: 78px;
    width: 148px;
}

.fb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.gugl {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.mg {
    margin-top: -48px;
}

.icon-set {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.place-holder-white {

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        /*/ / color: white !important;*/
        color: rgba(255, 255, 255, 0.7) !important;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        /*/ / color: white !important;*/
        color: rgba(255, 255, 255, 0.7) !important;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        /*/ / color: white !important;*/
        color: rgba(255, 255, 255, 0.7) !important;
    }

}

.bdr-radius {
    border-radius: 2px;
}

.icon-white {

    svg {
        color: white !important;
    }

}

/*..mx-icon-calendar*/
.mx-icon-calendar, .mx-icon-clear {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 1;
    color: white !important;
    vertical-align: middle;
}

@media (max-width: 750px) {
    .margin-1 {
        margin-top: 40px;
    }
}

</style>
